import RootState from '@vue-storefront/core/types/RootState'
import CartModuleExtendedState from '../types/CartModuleExtendedState'
import { Module } from 'vuex'
import actions from './actions'

export const module: Module<CartModuleExtendedState, RootState> = {
  namespaced: true,
  state: {
    cartExtended: {}
  },
  actions
}
