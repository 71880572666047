import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { extendStore } from '@vue-storefront/core/helpers'

export const STORAGE_KEY = 'newsletter'

export const NewsletterModuleExtended: StorefrontModule = function () {
  StorageManager.init(STORAGE_KEY)

  extendStore(STORAGE_KEY, module)
}
