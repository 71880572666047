export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    accountMenu: false,
    microcart: false,
    microcartLoader: false,
    microcartLastProductAdded: null,
    wishlist: false,
    clientservice: false,
    categorySortBy: false,
    searchpanel: false,
    search: false,
    isContactUsSidebarOpen: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    blogCategories: false,
    submenu: {
      depth: false,
      path: []
    },
    megamenu: false,
    megamenuEnterIt: null,
    megamenuLeaveIt: null,
    megamenuHover: false,
    megamenuEnabled: true,
    lastOrderConfirmation: false,
    productPopUp: false,

    filterBySelected: []
  },
  mutations: {
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setMicrocartLoader (state, action) {
      state.microcartLoader = action === true
    },
    setMicrocartLastProductAdded (state, action) {
      state.microcartLastProductAdded = action
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setAccountMenu (state, action) {
      state.accountMenu = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearch (state, action) {
      state.search = action
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setIsContactUsSidebarOpen (state, action) {
      state.isContactUsSidebarOpen = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      state.wishlist = action === true
      state.overlay = action === true
    },
    setClientService (state, action) {
      state.clientservice = action === true
      state.overlay = action === true
    },
    setBlogCategories (state, action) {
      state.blogCategories = action === true
      state.overlay = action === true
    },
    setCategorySortBy (state, action) {
      state.categorySortBy = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    },
    setMegaMenu (state, action) {
      state.megamenu = action
    },
    setMegaMenuHover (state, action) {
      state.megamenuHover = action === true
    },
    setMegaMenuEnabled (state, action) {
      state.megamenuEnabled = action === true
    },
    setLastOrderConfirmation (state, payload) {
      state.lastOrderConfirmation = payload
    },
    setProductPopUp (state, action) {
      state.productPopUp = action
    },
    toggleFilterBySelected (state, action) {
      const idx = state.filterBySelected.indexOf(action)
      if (idx >= 0) {
        state.filterBySelected.splice(idx, 1)
      } else {
        state.filterBySelected.push(action)
      }
    },
    clearFilterType (state, action) {
      state.filterBySelected = state.filterBySelected.filter(filter => filter.type !== action)
    },
    removeFilterBySelected (state, action) {
      const idx = state.filterBySelected.indexOf(action)
      if (idx >= 0) {
        state.filterBySelected.splice(idx, 1)
      }
    },
    clearFilterBySelected (state) {
      state.filterBySelected = []
    }
  },
  actions: {
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleMicrocartLoader ({ commit, state }) {
      commit('setMicrocartLoader', !state.microcartLoader)
    },
    enableMicrocartLoader ({ commit, state }) {
      commit('setMicrocartLoader', true)
    },
    disableMicrocartLoader ({ commit, state }) {
      commit('setMicrocartLoader', false)
    },
    unsetMicrocartLastProductAdded ({ commit, state }) {
      commit('setMicrocartLastProductAdded', null)
    },
    setMicrocartLastProductAdded ({ commit, state }) {
      commit('setMicrocartLastProductAdded', state)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    toggleClientService ({ commit, state }) {
      commit('setClientService', !state.clientservice)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    },
    closeWishlist ({ commit, state }) {
      if (state.wishlist) commit('setWishlist', false)
    },
    closeClientService ({ commit, state }) {
      if (state.clientservice) commit('setClientService', false)
    },
    openBlogCategories ({ commit, state }) {
      if (!state.blogCategories) commit('setBlogCategories', true)
    },
    closeBlogCategories ({ commit, state }) {
      if (state.blogCategories) commit('setBlogCategories', false)
    },
    setMegaMenuCategory ({ commit, state }, category) {
      clearInterval(state.megamenuEnterIt)
      state.megamenuEnterIt = setTimeout(() => {
        commit('setMegaMenu', category)
      }, 200)
    },
    mouseoverMegaMenu ({ commit, state }) {
      clearInterval(state.megamenuLeaveIt)
      state.megamenuEnterIt = setTimeout(() => {
        commit('setMegaMenuHover', true)
      }, 500)
    },
    mouseleaveMegaMenu ({ commit, state }) {
      clearInterval(state.megamenuEnterIt)
      state.megamenuLeaveIt = setTimeout(() => {
        commit('setMegaMenuHover', false)
      }, 500)
    },
    clearLastOrderConfirmation ({ commit, state }) {
      commit('setLastOrderConfirmation', false)
    }
  },
  getters: {
    getMegaMenuVisibility (state) {
      return state.megamenuHover && state.megamenuEnabled && state.megamenu
    },
    getMicrocartLoaderVisibility (state) {
      return state.microcartLoader
    },
    getLastOrderConfirmation (state) {
      return state.lastOrderConfirmation
    }
  }
}
