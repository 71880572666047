import { Module } from 'vuex'
import GiftCardState from 'src/modules/mageworx-giftcard/types/GiftCardState'
import { CART_SET_ITEMS_HASH } from '@vue-storefront/core/modules/cart/store/mutation-types'
import * as types from './mutation-types'
import { GiftCardService } from 'src/modules/mageworx-giftcard/helpers/dataResolvers'
import i18n from '@vue-storefront/core/i18n'
import RootState from 'core/types/RootState'
import isEqual from 'lodash-es/isEqual'

export const GiftCardModule: Module<GiftCardState, RootState> = {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    [types.GIFTCARD_ADD_TO_CART] (state, giftCards) {
      state.items = giftCards
    }
  },
  actions: {
    async check ({ dispatch, commit }, giftCardCode) {
      if (giftCardCode) {
        const response = await GiftCardService.checkGiftCard(giftCardCode)

        if (response.code !== 200) {
          dispatch('notification/spawnNotification',
            {
              type: 'error',
              message: i18n.t(response.result.errorMessage),
              action1: { label: i18n.t('OK') }
            }, { root: true })

          return Promise.reject(response.result.errorMessage)
        }
        return Promise.resolve(response.result)
      }
    },
    async activate ({ rootGetters, dispatch, commit }, giftCardCode) {
      if (giftCardCode && rootGetters['cart/canSyncTotals']) {
        const { result, code } = await GiftCardService.activateGiftCard(giftCardCode)

        if (result && code === 200) {
          await dispatch('cart/syncTotals', { forceServerSync: true }, { root: true })

          dispatch('notification/spawnNotification',
            {
              type: 'success',
              message: i18n.t('Gift card successfully added!'),
              action1: { label: i18n.t('OK') }
            }, { root: true })

          commit(`cart/${CART_SET_ITEMS_HASH}`, rootGetters['cart/getCurrentCartHash'], { root: true })
        }
        return result
      }
    },
    async remove ({ rootGetters, dispatch, commit }, giftCardCode, sync = true) {
      if (giftCardCode && rootGetters['cart/canSyncTotals']) {
        const { result, code } = await GiftCardService.removeGiftCard(giftCardCode)

        if (result && code === 200 && sync) {
          await dispatch('cart/syncTotals', { forceServerSync: true }, { root: true })

          dispatch('notification/spawnNotification',
            {
              type: 'success',
              message: i18n.t('Gift card successfully removed!'),
              action1: { label: i18n.t('OK') }
            }, { root: true })

          commit(`cart/${CART_SET_ITEMS_HASH}`, rootGetters['cart/getCurrentCartHash'], { root: true })
        }

        return result
      }
    }
  },
  getters: {
    currentGiftCards: ({ items }) => items.length ? items : null
  }
}
