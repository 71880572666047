<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal')
const BaseLayout = () => import(/* webpackChunkName: "vsf-layout-base" */ './layouts/Base')

export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout,
    BaseLayout
  },
  data () {
    return {
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  mounted () {
    this.$router.beforeEach((to, from, next) => {
      if (to.name.includes('-checkout') && StorageManager.get('checkout').getItem('status') === 'cancelled') {
        this.$store.dispatch('checkout/load')
      }
      next()
    })
  }
}
</script>
