import { GetterTree } from 'vuex'
import CheckoutState from '../types/InfoBannerState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<CheckoutState, RootState> = {
  bannerStatus: state => state.bannerStatus,
  bannerContent: state => state.bannerContent
}

export default getters
