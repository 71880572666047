import { Logger } from '@vue-storefront/core/lib/logger'
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import { SearchQuery } from 'storefront-query-builder'
async function handleBrands ({ dispatch }, brand) {
  if (brand) {
    Logger.info('Matched brand url fallback', 'brands')()
    return {
      name: 'category',
      params: {
        brand: {
          label: brand.label
        },
        manufacturer: parseInt(brand.brand_id, 10)
      }
    }
  }
  return false
}
export default async function (context, { url }) {
  url = (removeStoreCodeFromRoute(url) as string).replace(/\.*\/?$/, '').replace(/^\//, '')
  const brands = await context.dispatch('brands/fetchBrands', { requestPath: url }, { root: true })
  if (brands && brands.items && brands.items.length > 0) {
    const brand = brands.items[0]
    if (brand) {
      return handleBrands(context, brand)
    }
  }
  return false
}
