import ClerkState from '../types/ClerkState';
import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'

export const ClerkModule: Module<ClerkState, RootState> = {
  namespaced: true,
  state: {
    clerk: {},
    visitorKey: ''
  },
  actions,
  mutations,
  getters
}
