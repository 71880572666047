import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import InfoBannerState from '../types/InfoBannerState'
import config from 'config'

const actions: ActionTree<InfoBannerState, RootState> = {
  getBannerStatus ({ commit }, storeCode) {
    fetch(config.api.url + '/api/ext/info-banner/banner/check/' + storeCode, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }).then(res => {
      return res.json()
    }).then(res => {
      commit(types.SET_INFO_BANNER_STATUS, res.result === 'true');
    })
  },
  getBannerContent ({ commit }, storeCode) {
    fetch(config.api.url + '/api/ext/info-banner/banner/get/' + storeCode, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }).then(res => {
      return res.json()
    }).then(res => {
      commit(types.SET_INFO_BANNER_CONTENT, JSON.parse(res.result));
    })
  }
}

export default actions
