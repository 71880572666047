import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CartModuleExtendedState from '../types/CartModuleExtendedState'
import createDiffLog from '@vue-storefront/core/modules/cart/helpers/createDiffLog'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { CartService } from '@vue-storefront/core/data-resolver'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'

const actions: ActionTree<CartModuleExtendedState, RootState> = {
  async syncAppliedCoupon ({ getters, rootGetters, commit, dispatch, state }, { forceClientState = false, dryRun = false, mergeQty = false, forceSync = false }) {
    const { getCartItems, canUpdateMethods, isSyncRequired, bypassCounter } = getters
    if ((!canUpdateMethods || !isSyncRequired) && !forceSync) return createDiffLog()
    commit(types.CART_SET_SYNC)
    const { result, resultCode } = await CartService.getItems()
    const { serverItems, clientItems } = cartHooksExecutors.beforeSync({ clientItems: getCartItems, serverItems: result })

    if (resultCode === 200) {
      const diffLog = await dispatch('merge', {
        dryRun,
        serverItems,
        clientItems,
        forceClientState: forceClientState,
        mergeQty
      })
      cartHooksExecutors.afterSync(diffLog)
      return diffLog
    }

    if (bypassCounter < config.queues.maxCartBypassAttempts) {
      Logger.log('Bypassing with guest cart' + bypassCounter, 'cart')()
      commit(types.CART_UPDATE_BYPASS_COUNTER, { counter: 1 })
      await dispatch('connect', { guestCart: true })
    }

    Logger.error(result, 'cart')
    cartHooksExecutors.afterSync(result)
    commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
    return createDiffLog()
  },
  async create ({ dispatch, getters }) {
    const cartToken = getters['getCartToken']
    if (!cartToken) {
      Logger.info('Creating server cart token', 'cart')()
      return dispatch('connect', { guestCart: false })
    }
  },
  async synchronizeServerItem ({ dispatch }, { serverItem, clientItem, forceClientState, dryRun, mergeQty }) {
    const diffLog = createDiffLog()

    if (!serverItem) {
      Logger.warn('No server item with sku ' + clientItem.sku + ' on stock.', 'cart')()
      diffLog.pushServerParty({ sku: clientItem.sku, status: 'no-item' })

      if (dryRun) return diffLog
      /** Force sever item for free gifts */
      const customOptions = clientItem.product_option?.extension_attributes?.custom_options || {}
      let isItemFree = Object.values(customOptions).some(
        (option) => (option as { option_id?: string })?.option_id === 'ampromo_rule_id'
      ) || false
      if (forceClientState || (!config.cart.serverSyncCanRemoveLocalItem && !isItemFree)) {
        const updateServerItemDiffLog = await dispatch('updateServerItem', { clientItem, serverItem, updateIds: false })
        return diffLog.merge(updateServerItemDiffLog)
      }

      await dispatch('removeItem', { product: clientItem })
      return diffLog
    }

    if (serverItem.qty !== clientItem.qty || mergeQty) {
      Logger.log('Wrong qty for ' + clientItem.sku, clientItem.qty, serverItem.qty)()
      diffLog.pushServerParty({ sku: clientItem.sku, status: 'wrong-qty', 'client-qty': clientItem.qty, 'server-qty': serverItem.qty })
      if (dryRun) return diffLog
      if (forceClientState || !config.cart.serverSyncCanModifyLocalItems) {
        const updateServerItemDiffLog = await dispatch('updateServerItem', { clientItem, serverItem, updateIds: true, mergeQty })

        return diffLog.merge(updateServerItemDiffLog)
      }

      await dispatch('updateItem', { product: serverItem })
    }

    return diffLog
  },
  async authorize ({ dispatch, getters }) {
    const coupon = getters.getCoupon.code
    if (coupon) {
      await dispatch('removeCoupon', { sync: false })
    }

    await dispatch('connect', { guestCart: false, mergeQty: true })
    await dispatch('syncTotals', { forceServerSync: true })

    if (coupon) {
      await dispatch('applyCoupon', coupon)
    }
    /** Sync to update gifts from server */
    await dispatch('syncAppliedCoupon', { forceSync: true })
  }
}

export default actions
