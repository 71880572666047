import RootState from 'core/types/RootState';
import { GetterTree } from 'vuex';
import BrandsState from '../types/BrandsState';

const getters: GetterTree<BrandsState, RootState> = {
  getBrands: (state) => {
    return state.brands
  }
}

export default getters
