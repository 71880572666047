import RootState from 'core/types/RootState';
import { GetterTree } from 'vuex';
import ClerkState from '../types/ClerkState';

const getters: GetterTree<ClerkState, RootState> = {
  getClerk: (state) => {
    return state.clerk
  },
  visitorKey: (state) => {
    return state.visitorKey
  }
}

export default getters
