import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'

export const accountStore = {
  namespaced: true,
  state: {
    socialLinks: []
  },
  mutations: {
    SET_SOCIALLINKS (state, socialLinks) {
      state.socialLinks = socialLinks || []
    },
    DELETE_LINK (state, id) {
      state.socialLinks = state.socialLinks.filter(link => {
        return link.sociallogin_id !== id
      })
    },
    CLEAR_LINKS (state) {
      state.socialLinks = []
    }
  },
  actions: {
    async loadSocialLinks ({ commit }, token) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.social_login.links),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ token })
        }
      })

      if (response.code === 200 && response.result) {
        commit('SET_SOCIALLINKS', response.result.filter(link => link.type))
      }
    },
    async unlinkAccount ({ commit }, id) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.social_login.unlink),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ link_id: id })
        }
      })

      if (response.code === 200 && response.result) {
        commit('DELETE_LINK', id)
      }
    },
    async clearLinks ({ commit }) {
      commit('CLEAR_LINKS')
    }
  },
  getters: {
    getSocialLinks (state) {
      return state.socialLinks
    }
  }
}
