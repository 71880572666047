import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { ClerkModule } from './store'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'
export const STORAGE_KEY = 'clerk'

export const Clerk: StorefrontModule = function ({ router, store }) {
  StorageManager.init(STORAGE_KEY)
  store.registerModule(STORAGE_KEY, ClerkModule)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('clerk', {
      queryProcessor: query => query,
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'clerk', start, size)
      }
    })
  })
}
