import { ActionTree } from 'vuex'
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState'
import RootState from '@vue-storefront/core/types/RootState'
import { buildFilterProductsQuery, buildFilterProductsQueryAsync } from '@vue-storefront/core/helpers'
import * as types from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { entities, products } from 'config'
import { router } from '@vue-storefront/core/app'
import { SearchQuery } from 'storefront-query-builder'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import uniq from 'lodash-es/uniq'
import flatten from 'lodash-es/flatten';
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant';
import { changeFilterQuery } from '@vue-storefront/core/modules/catalog-next/helpers/filterHelpers';
import { ProductService } from '@vue-storefront/core/data-resolver/ProductService';

const actions: ActionTree<CategoryState, RootState> = {
  async loadCategoryProducts ({ commit, getters, dispatch }, { route, category, pageSize = 50, query = null, isClerk = false } = {}) {
    const searchCategory = category || getters.getCategoryFrom(route.path) || {}
    const categoryMappedFilters = getters.getFiltersMap[searchCategory.id]
    const areFiltersInQuery = !!Object.keys(route[products.routerFiltersSource]).length
    const findProductsPath = isClerk ? 'clerk/findPageProducts' : 'product/findProducts';
    if (!categoryMappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
      await dispatch('loadCategoryFilters', searchCategory)
    }
    const searchQuery = getters.getCurrentFiltersFrom(route[products.routerFiltersSource], categoryMappedFilters)
    const sortValue = route[products.routerFiltersSource]

    let filterQr = await buildFilterProductsQueryAsync(searchCategory, searchQuery.filters)
    if (query) {
      filterQr = filterQr
        .setSearchText(query.slug)

      if (query.params.length > 0) {
        filterQr = filterQr.applyFilter({ key: 'category_ids', value: { 'in': query.params } })
      }
    }

    // Custom for Belomax
    if (
      route[products.routerFiltersSource].price &&
      !filterQr.getAppliedFilters().some(filter => filter.attribute === 'price')
    ) {
      const priceFilter = route[products.routerFiltersSource].price
      const rangeqr = {}
      const filterValues = Array.isArray(priceFilter) ? priceFilter : [priceFilter]
      filterValues.forEach(singleFilter => {
        const [from, to] = singleFilter.split('-')
        rangeqr['gte'] = parseFloat(from)
        rangeqr['lte'] = parseFloat(to)
      })
      filterQr = filterQr.applyFilter({ key: 'price', value: rangeqr, scope: 'catalog' })
    }

    if (route.params.manufacturer && !filterQr.getAppliedFilters().some(filter => filter.attribute === 'manufacturer')) {
      filterQr = filterQr.applyFilter({ key: 'manufacturer', value: route.params.manufacturer, scope: 'default' })
    }

    if (entities.productList.filterEndOfLife) {
      filterQr.applyFilter({ key: 'eol', value: 0 })
    }

    const customSortScript = `
        // Sorting in ascending order from smallest number to biggest
        double pos = 0;
        if (params._source.stock.is_in_stock == false) {
          return 100002;
        }
        if (params._source.category != null) {
          for (def cat : params._source.category) {
            if (cat.category_id == ${searchCategory.id}) {
              pos = cat.position;
              break;
            }
          }
        }
        if (pos > 0) {
          return pos;
        }
        if (params._source.sold_qty != null && params._source.sold_qty > 0) {
          return 100000 - params._source.sold_qty;  // Sort by sold_qty for products without a position
        } else {
          return 100001;
        }
      `;

    if (isClerk && sortValue.sort) {
      Object.assign(searchQuery, { sort: `stock_status_for_filter,${sortValue.sort}` })
    } else if (searchQuery.sort && searchQuery.sort !== 'sold_qty:desc') {
      Object.assign(searchQuery, { sort: `stock_status_for_filter,${searchQuery.sort}` })
    } else {
      filterQr = filterQr.applySort({
        field: '_script',
        options: {
          type: 'number',
          script: {
            source: customSortScript,
            lang: 'painless'
          },
          order: 'asc'
        }
      });
    }

    const { items, perPage, start, total, aggregations, attributeMetadata } = await dispatch(findProductsPath, {
      query: filterQr,
      sort: searchQuery.sort || `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`,
      size: pageSize,
      start: route.query.page ? Math.max(pageSize * (route.query.page - 1), 0) : 0,
      configuration: searchQuery.filters,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: true
      }
    }, { root: true })

    // End custom
    await dispatch('loadAvailableFiltersFrom', {
      aggregations,
      attributeMetadata,
      category: searchCategory,
      filters: searchQuery.filters
    })

    commit(types.CATEGORY_SET_SEARCH_PRODUCTS_STATS, { perPage, start, total })
    commit(types.CATEGORY_SET_PRODUCTS, items)
    commit('SET_AGGREGATIONS', aggregations)

    return items
  },
  async changeRouterFilterParameters (context, query) {
    const route: any = { [products.routerFiltersSource]: query }

    if (router.currentRoute.query.page) {
      if (!route.query) route.query = {}
      route.query.page = 1
    }

    router.push(route)
  },
  async changeRouterFilterParametersWithFilterVariants (context, { query, filterAttribute }) {
    let currentRouteFullPath = router.currentRoute.fullPath
    let currentRoutePath = router.currentRoute.path
    let filterAttributes = router.currentRoute.params.filterAttribute

    let newFullPath = currentRouteFullPath.replace('/' + filterAttributes, '/' + filterAttribute)
    let newPath = currentRoutePath.replace('/' + filterAttributes, '/' + filterAttribute)
    const normalizedPath = `${newPath.startsWith('/') ? '' : '/'}${newPath}`
    const route: any = {
      fullPath: newFullPath,
      path: newPath,
      name: `urldispatcher-${normalizedPath}`,
      params: {
        slug: context.rootGetters['category/getCurrentCategory'].slug,
        filterAttribute: filterAttribute
      },
      [products.routerFiltersSource]: query
    }

    if (router.currentRoute.query.page) {
      if (!route.query) route.query = {}
      route.query.page = 1
    }

    router.push(route)
  },
  setPaginationNumbersCount (context, number) {
    context.commit('CATEGORY_SET_PAGINATION_NUMBERS', number)
  },
  async loadCategoryMinMaxPrice ({ commit }, { category, query }) {
    let maxQuery = new SearchQuery()
    maxQuery = maxQuery.applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
    maxQuery = maxQuery.applyFilter({ key: 'status', value: { 'in': [0, 1] } })

    if (entities.productList.filterEndOfLife) {
      maxQuery = maxQuery.applyFilter({ key: 'eol', value: 0 })
    }

    let categoryIds = [category.id]
    if (category.children_data) {
      let recurCatFinderBuilder = (category) => {
        if (!category) {
          return
        }

        if (!category.children_data) {
          return
        }

        for (let sc of category.children_data) {
          if (sc && sc.id) {
            categoryIds.push(sc.id)
          }
          recurCatFinderBuilder(sc)
        }
      }
      recurCatFinderBuilder(category)
    }

    maxQuery = maxQuery.applyFilter({ key: 'category_ids', value: { 'in': categoryIds } })

    const maxResponse = await quickSearchByQuery({
      query: maxQuery,
      sort: 'price_filter:desc',
      size: 1,
      includeFields: ['price_filter']
    })

    const minResponse = await quickSearchByQuery({
      query: maxQuery,
      sort: 'price_filter:asc',
      size: 1,
      includeFields: ['price_filter']
    })

    if (maxResponse && maxResponse.items && maxResponse.items.length) {
      const first = maxResponse.items[0]
      commit('SET_MAX_PRICE', Math.ceil(parseFloat(first.price_filter)))
    }

    if (minResponse && minResponse.items && minResponse.items.length) {
      const first = minResponse.items[0]
      commit('SET_MIN_PRICE', Math.floor(parseFloat(first.price_filter)))
    }
  },
  async loadClerkMinMaxPrice ({ commit }, { query }) {
    query['_appliedFilters'] = query['_appliedFilters'].filter((filter) => filter.attribute !== 'price');
    const { items } = await ProductService.getProducts({ query })
    if (items) {
      let minPrice = items.reduce((prev, curr) => prev.final_price < curr.final_price ? prev : curr).final_price
      let maxPrice = items.reduce((prev, curr) => prev.final_price > curr.final_price ? prev : curr).final_price

      commit('SET_MIN_PRICE', Math.floor(minPrice))
      commit('SET_MAX_PRICE', Math.ceil(maxPrice))
    }
  },
  async loadQueryCategories ({ commit, getters }, { route, category, pageSize = 50, query = null } = {}) {
    const searchCategory = category || getters.getCategoryFrom(route.path) || {}

    const categoryMappedFilters = getters.getFiltersMap[searchCategory.id]

    const searchQuery = getters.getCurrentFiltersFrom(route[products.routerFiltersSource], categoryMappedFilters)
    let filterQr = buildFilterProductsQuery(searchCategory, searchQuery.filters)

    if (query) {
      filterQr = filterQr
        .setSearchText(query.slug)

      if (query.params.length > 0) {
        filterQr = filterQr.applyFilter({ key: 'category_ids', value: { 'in': query.params } })
      }
    }

    const { items } = await quickSearchByQuery({
      query: filterQr,
      includeFields: ['category'],
      excludeFields: entities.productList.excludeFields,
      size: 1000
    })

    const categories = flatten(items.map(product => product.category))

    const unique = uniq(categories.map(cat => cat.category_id).filter(catId => catId !== category.id))

    commit('CATEGORY_SET_SEARCH_QUERY_CATS', unique.map(id => categories.find(category => category.category_id === id)))

    return items
  },
  async switchSearchFilters ({ dispatch, rootState }, filterVariants: FilterVariant[] = []) {
    let currentQuery = router.currentRoute[products.routerFiltersSource]
    const filterAttribute = router.currentRoute.params['filterAttribute'] || null
    let filterVariantFilters = {}

    let filterAttributeSplit = []
    let filterVariantsChanged = false

    if (filterAttribute) {
      filterAttributeSplit = filterAttribute.split('-')
      if (filterAttributeSplit.length) {
        Object.keys(rootState.attribute.list_by_code).forEach(key => {
          let data = rootState.attribute.list_by_code[key]
          if (!data.hasOwnProperty('options') || data.options.length <= 0) {
            return
          }
          Object.keys(data.options).forEach(optionKey => {
            let optionData = data.options[optionKey]
            if (!optionData.hasOwnProperty('label') || !optionData.label) {
              return
            }
            let label = optionData.label.toLowerCase().split(' ').join('_')
            if (filterAttributeSplit.indexOf(label) < 0) {
              return
            }
            if (!currentQuery.hasOwnProperty(data.attribute_code)) {
              currentQuery[data.attribute_code] = []
            }
            currentQuery[data.attribute_code].push(optionData.value)

            if (!filterVariantFilters.hasOwnProperty(data.attribute_code)) {
              filterVariantFilters[data.attribute_code] = []
            }
            filterVariantFilters[data.attribute_code].push({
              value: optionData.value,
              key: label
            })
          })
        })
      }
    }

    filterVariants.forEach(filterVariant => {
      currentQuery = changeFilterQuery({ currentQuery, filterVariant })
    })

    Object.keys(filterVariantFilters).forEach(key => {
      let data = filterVariantFilters[key]
      Object.keys(data).forEach(attributeKey => {
        let attributeData = data[attributeKey]
        if (currentQuery.hasOwnProperty(key)) {
          if (currentQuery[key].indexOf(attributeData.value) !== -1) {
            currentQuery[key].splice(attributeKey, 1)
            if (currentQuery[key].length === 0) {
              delete currentQuery[key]
            }
            return;
          }
        }
        filterAttributeSplit.splice(filterAttributeSplit.indexOf(attributeData.key), 1)
        filterVariantsChanged = true
      })
    })

    if (filterVariantsChanged) {
      await dispatch('changeRouterFilterParametersWithFilterVariants', {
        query: currentQuery,
        filterAttribute: filterAttributeSplit.join('-')
      })
    } else {
      console.log(filterAttributeSplit)
      await dispatch('changeRouterFilterParameters', currentQuery)
    }
  },
  async loadClerkCategories ({ commit, getters }, { items } = {}) {
    const categories = flatten(items.map(product => product.category))
    const unique = uniq(categories.map(cat => cat.category_id)) // .filter(catId => catId !== category.id))

    commit('CATEGORY_SET_SEARCH_QUERY_CATS', unique.map(id => categories.find(category => category.category_id === id)))

    return items
  }
}

export default actions
