import { ProductModule } from './store/product'
import { CartModule } from './store/cart'
import { GiftCardModule } from './store/giftCard'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { extendStore } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from './store/giftCard/mutation-types';

const GiftCardModuleKey = 'gift-card'

export const MageworxGiftcard: StorefrontModule = function ({ store }) {
  extendStore('product', ProductModule)
  extendStore('cart', CartModule)
  store.registerModule(GiftCardModuleKey, GiftCardModule)

  EventBus.$on('cart-after-updatetotals', totals => {
    const platformTotals = totals.platformTotalSegments

    if (platformTotals.length) {
      const giftCards = []
      const mageworxTotals = platformTotals.find(total => total.code === 'mageworx_giftcards')

      if (mageworxTotals && mageworxTotals.extension_attributes) {
        giftCards.push(...JSON.parse(mageworxTotals.extension_attributes.front_options))
      }

      return store.commit(`${GiftCardModuleKey}/${types.GIFTCARD_ADD_TO_CART}`, giftCards)
    }
  })
}
