import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import InfoBannerState from '../types/InfoBannerState'

const mutations: MutationTree<InfoBannerState> = {
  [types.SET_INFO_BANNER_STATUS] (state, payload) {
    state.bannerStatus = payload
  },
  [types.SET_INFO_BANNER_CONTENT] (state, payload) {
    state.bannerContent = payload
  }
}

export default mutations
