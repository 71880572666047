import BrandsState from '../types/BrandsState';
import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'

export const module: Module<BrandsState, RootState> = {
  namespaced: true,
  state: {
    brands: {}
  },
  actions,
  mutations,
  getters
}
