import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { userHooks } from '@vue-storefront/core/modules/user/hooks'
import * as types from '@vue-storefront/core/modules/user/store/mutation-types'
import UserState from '@vue-storefront/core/modules/user/types/UserState'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { Module } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export const module: Module<UserState, RootState> = {
  namespaced: true,
  actions: {
    async auth ({ dispatch, commit }, { type, data }) {
      const currentStore = currentStoreView()
      const storeCode = currentStore.storeCode

      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.social_login.endpoint),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ type, storeCode, ...data })
        }
      })

      userHooks.afterUserAuthorize(response.result)

      if (response.code === 200) {
        try {
          await dispatch('resetUserInvalidateLock', {}, { root: true })
          commit('user/' + types.USER_TOKEN_CHANGED, { newToken: response.result, meta: response.meta }, { root: true })
          await dispatch('user/sessionAfterAuthorized', { refresh: true, useCache: false }, { root: true })
        } catch (err) {
          await dispatch('user/clearCurrentUser', { root: true })
          throw new Error(err)
        }
      } else {
        throw new Error(response.result.errorMessage)
      }
      return response
    },
    async link ({ dispatch, commit }, { type, customerId, data, storeCode }) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.social_login.linkAccount),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ type, customerId, storeCode, ...data })
        }
      })

      if (response.code !== 200) {
        throw new Error(response.result.errorMessage)
      }

      return response
    }
  }
}
