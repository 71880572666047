import merge from 'lodash-es/merge'
import { breadcrumbsStore } from '@vue-storefront/core/modules/breadcrumbs/store'

export const breadcrumbsExtendedStore = merge(breadcrumbsStore, {
  state: {
    isChanging: false
  },
  mutations: {
    set (state, payload) {
      state.routes = payload.routes
      state.current = payload.current
      state.isChanging = false
    },
    setChanging (state, action) {
      state.isChanging = action === true
    }
  },
  getters: {
    getBreadcrumbsChanging: (state) => state.isChanging
  },
  actions: {
    enableIsChanging ({ commit, state }) {
      commit('setChanging', true)
    }
  }
})

export const breadcrumbsExtended = breadcrumbsExtendedStore
