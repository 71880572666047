import { currentStoreView, localizedDispatcherRouteName, removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import { Logger } from '@vue-storefront/core/lib/logger'
import { Payload } from '../types/Payload'

export const forCmsPage = async ({ dispatch }, { url }: Payload) => {
  const { id, storeCode, appendStoreCode } = currentStoreView()
  url = (removeStoreCodeFromRoute(url.replace(/^\//, '')) as string)
  try {
    const cmsPage = await dispatch('cmsPage/single', { key: 'identifier', value: url }, { root: true })
    if (cmsPage !== null) {
      return {
        name: localizedDispatcherRouteName('cms-page', id || storeCode, appendStoreCode),
        params: {
          slug: cmsPage.identifier
        }
      }
    }
  } catch {
    Logger.info('Url: ' + url, 'MappingFallback')()
    return null
  }
}
