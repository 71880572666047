import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import { ActionTree } from 'vuex';
import { STORAGE_KEY } from '..'
import { createLoadUrlRewritesQuery } from '../helpers'
import BrandsState from '../types/BrandsState'
import { SearchQuery } from 'storefront-query-builder'
import * as types from './mutation-types'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'

const actions: ActionTree<BrandsState, RootState> = {
  async fetchBrands ({ commit }, {
    requestPath, start = 0, size = 50, entityType = 'brands', rewriteEntities = config.brands.entities, excludeFields = null, includeFields = ['brand_id', 'url_key', 'label', 'img']
  }) {
    const query = createLoadUrlRewritesQuery({ requestPath })
    const response = await quickSearchByQuery({ query, start, size, entityType, excludeFields, includeFields })

    if (response.items) {
      commit(types.UPDATE_BRAND, response.items)
    }

    return response
  },
  async fetchAllBrands ({ commit }) {
    const response = await quickSearchByQuery({ entityType: 'brands', includeFields: ['brand_id', 'url_key', 'label', 'img'] })

    if (response.items) {
      commit(types.UPDATE_BRAND, response.items)
    }

    return response
  },
  async getBrand ({ state, dispatch, commit }, urls) {
    const cacheStorage = StorageManager.get(STORAGE_KEY)
    if (!Array.isArray(urls)) {
      urls = [urls]
    }

    for (const url of urls) {
      const cacheItem = await cacheStorage.getItem(url)

      if (cacheItem !== null) {
        Logger.info(`Cache hit for brand with url ${url} ->`, 'brands')()
        return cacheItem
      }
    }

    const brands = await dispatch('fetchBrands', { requestPath: urls })

    if (brands && brands.items && brands.items.length > 0) {
      const brand = brands.items[0]
      cacheStorage.setItem(brand.url_key, brand)
      return brand
    }

    for (const url of urls) {
      cacheStorage.setItem(url, false)
      commit(types.ADD_BRAND, {
        path: url,
        value: false
      })
    }

    return false
  },
  async getUrlKey (store, id) {
    let query = new SearchQuery();
    query.applyFilter({
      key: 'brand_id',
      value: { eq: id }
    })

    const response = await quickSearchByQuery({ query, entityType: 'brands', includeFields: ['url_key'] });

    if (response.items) {
      return response.items[0]
    }
  }
}

export default actions
