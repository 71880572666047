import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { isServer } from '@vue-storefront/core/helpers'
import { module } from './store'
import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader'
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'

export const STORAGE_KEY = 'brands'

export const Brands: StorefrontModule = function ({ router, store }) {
  StorageManager.init(STORAGE_KEY)

  store.registerModule(STORAGE_KEY, module)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('brands', {
      queryProcessor: query => query,
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'brands', start, size)
      }
    })
  })
}
