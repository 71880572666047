import { MutationTree } from 'vuex';
import ClerkState from '../types/ClerkState';
import * as types from './mutation-types'
import { SET_CROSS_SELL_PRODUCTS, SET_SEARCH_RESULT, SET_VISITOR_KEY } from './mutation-types';

const mutations: MutationTree<ClerkState> = {
  [types.SET_CROSS_SELL_PRODUCTS] (state, items) {
    state.clerk['cross_sell'] = items
  },
  [types.SET_SEARCH_RESULT] (state, items) {
    state.clerk['search_result'] = items
  },
  [types.SET_VISITOR_KEY] (state, visitorKey) {
    state.visitorKey = visitorKey
  }
}

export default mutations
