import { MutationTree } from 'vuex';
import BrandsState from '../types/BrandsState';
import * as types from './mutation-types'

const mutations: MutationTree<BrandsState> = {
  [types.UPDATE_BRAND] (state, items) {
    items.forEach(item => {
      state.brands[item.url_key] = item
    })
  },
  [types.ADD_BRAND] (state, data) {
    state.brands[data.url_key] = data.value
  }
}

export default mutations
