import { ActionTree } from 'vuex'
import RootState from 'core/types/RootState';
import config from 'config'
import NewsletterModuleExtendedState from '../types/NewsletterModuleExtendedState';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { processURLAddress } from '@vue-storefront/core/helpers';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

const actions: ActionTree<NewsletterModuleExtendedState, RootState> = {
  async validateBlacklist (_state, email) {
    const apiUrl = processURLAddress(getApiEndpointUrl(config.mailchimpValidate, 'validate'))
    const response = await TaskQueue.execute({
      url: apiUrl,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({ email })
      }
    })

    return response.result;
  }
}

export default actions
