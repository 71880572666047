import RootState from '@vue-storefront/core/types/RootState'
import { ActionTree } from 'vuex';
import * as types from './mutation-types';
import ClerkState from '../types/ClerkState'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import { ProductService } from '@vue-storefront/core/data-resolver/ProductService';
import { registerProductsMapping, setRequestCacheTags } from '@vue-storefront/core/modules/catalog/helpers';
import { prepareProducts } from '@vue-storefront/core/modules/catalog/helpers/prepare';
import { isServer } from '@vue-storefront/core/helpers';
import { SearchQuery } from 'storefront-query-builder'

const actions: ActionTree<ClerkState, RootState> = {
  async initiateClerk (context) {
    let cs = document.createElement('script')
    cs.type = 'text/javascript'
    cs.innerHTML = '(function(w,d){var e=d.createElement(\'script\');e.type=\'text/javascript\';e.async=true;\n' +
        'e.src=(d.location.protocol==\'https:\'?\'https\':\'http\')+\'://cdn.clerk.io/clerk.js\';\n' +
        'var s=d.getElementsByTagName(\'script\')[0];s.parentNode.insertBefore(e,s);\n' +
        'w.__clerk_q=w.__clerk_q||[];w.Clerk=w.Clerk||function(){w.__clerk_q.push(arguments)};\n' +
        '})(window,document);\n' +
        '\n' +
        'Clerk(\'config\', {\n' +
        'key: \'' + config.clerk.apiKey + '\',\n' +
        'visitor: \'auto\'\n' +
        '});';
    document.head.appendChild(cs)
    await context.dispatch('clerk/fetchVisitorKey', {}, { root: true });
  },
  async fetchVisitorKey ({ commit }) {
    let response = await fetch(
      config.api.url + '/api/ext/clerk/visitor/key/',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }).then(res => {
      return res.json()
    })

    let result = JSON.parse(response.result)
    let visitorKey = result && result.status === 'ok' ? result.visitor : ''
    commit(types.SET_VISITOR_KEY, visitorKey);
    return visitorKey
  },
  async logSale (context, { data }) {
    if (data) {
      const body = {
        key: config.clerk.apiKey,
        visitor: context.state.visitorKey,
        email: data.email,
        sale: data.sale,
        products: data.products
      }
      await fetch(
        config.api.url + '/api/ext/clerk/log/sale/',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        }).then(res => {
        return res.json()
      })
    }
  },
  async logProductView (context, { productId }) {
    await fetch(config.api.url + '/api/ext/clerk/log/product/' + productId + '/' + context.state.visitorKey, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      mode: 'cors'
    }).then(res => {
      return res.json()
    })
  },
  async logClick (context, { productId }) {
    await fetch(config.api.url + '/api/ext/clerk/log/click/' + productId + '/' + context.state.visitorKey, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      mode: 'cors'
    }).then(res => {
      return res.json()
    })
  },
  async findProducts (context, {
    query,
    start = 0,
    size = 6,
    sort = '',
    excludeFields = null,
    includeFields = null,
    configuration = null,
    populateRequestCacheTags = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants
    } = {}
  } = {}) {
    let { searchResult } = await context.dispatch('clerk/getPageSearchResults', {
      originalQuery: query,
      limit: 10
    }, { root: true })
    let result = JSON.parse(searchResult.result)
    let productIds = [];
    let searchQuery = new SearchQuery()
    if (result.status === 'ok') {
      productIds = result.result;
    }
    searchQuery.applyFilter({ key: 'id', value: { 'in': productIds } })
      .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
      .applyFilter({ key: 'status', value: { 'in': [0, 1] } })
      .applyFilter({ key: 'eol', value: 0 })

    // Custom script to keep the same sorting as Clerk provides
    searchQuery = searchQuery.applySort({
      field: '_script',
      options: {
        type: 'number',
        script: {
          lang: 'painless',
          source: "List ids = params.ids; return ids.indexOf((int)doc['id'].value);",
          params: {
            ids: productIds
          }
        },
        order: 'asc'
      }
    });

    const { items } = await ProductService.getProducts({
      query: searchQuery,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    let products = prepareProducts(items)
    registerProductsMapping(context, products)

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }
    if (populateRequestCacheTags) {
      setRequestCacheTags({ products: products })
    }
    await context.dispatch('tax/calculateTaxes', { products: products }, { root: true })

    return { products }
  },
  async getSearchResults (context, { originalQuery = '', limit = 6 }) {
    const searchResult = await fetch(config.api.url + '/api/ext/clerk/search/predictive/' + limit + '/' +
        originalQuery + '/' + context.state.visitorKey, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }).then(res => {
      return res.json()
    })

    return { searchResult }
  },
  async getPageSearchResults (context, { originalQuery = '', limit = 500, offset = 0 }) {
    const visitorKey = context.state.visitorKey || await context.dispatch('clerk/fetchVisitorKey', {}, { root: true });
    const searchResult = await fetch(
      config.api.url + '/api/ext/clerk/search/search/' + limit + '/' + offset + '/' + originalQuery + '/' +
        visitorKey,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }).then(res => {
      return res.json()
    })

    return { searchResult }
  },
  async getCrossSellProducts (context, {
    productId,
    limit,
    populateRequestCacheTags = false
  } = {}) {
    let { searchResult } = await context.dispatch('clerk/getCrossSellProductIds', { productId, limit }, { root: true })
    let result = JSON.parse(searchResult.result)
    let productIds = [];
    let items = [];

    if (result.status === 'ok') {
      productIds = result.result;
    }

    let skipCache = true;

    for (const id of productIds) {
      let options = { 'id': id };
      const product = await ProductService.getProductByKey({ options, key: 'id', skipCache });
      if (product) {
        items.push(product);
      }
    }

    items = prepareProducts(items)
    registerProductsMapping(context, items)
    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }
    if (populateRequestCacheTags) {
      setRequestCacheTags({ products: items })
    }
    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true })

    return { items }
  },
  async getCrossSellProductIds (context, { productId = '', limit = 6 }) {
    const searchResult = await fetch(config.api.url + '/api/ext/clerk/products/cross-sell/' + limit + '/' +
        productId + '/' + context.state.visitorKey, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }).then(res => {
      return res.json()
    })

    return { searchResult }
  },
  async findPageProducts (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    excludeFields = null,
    includeFields = null,
    configuration = null,
    populateRequestCacheTags = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants
    } = {}
  } = {}) {
    let searchText = query['_searchText']
    let { searchResult } = await context.dispatch('clerk/getPageSearchResults', { originalQuery: searchText, limit: 200 }, { root: true })
    let result = JSON.parse(searchResult.result)
    let productIds = []
    if (result.status === 'ok') {
      productIds = result.result
    }

    query.setSearchText('')
      .applyFilter({ key: 'id', value: { 'in': productIds } })
      .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
      .applyFilter({ key: 'status', value: { 'in': [0, 1] } })
      .applyFilter({ key: 'eol', value: 0 })

    // Custom script to keep the same sorting as Clerk provides
    query = query.applySort({
      field: '_script',
      options: {
        type: 'number',
        script: {
          lang: 'painless',
          source: "List ids = params.ids; return ids.indexOf((int)doc['id'].value);",
          params: {
            ids: productIds
          }
        },
        order: 'asc'
      }
    });

    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }
    if (populateRequestCacheTags) {
      setRequestCacheTags({ products: items })
    }
    await context.dispatch('clerk/loadCategoryData', { query }, { root: true })

    return { items, ...restResponseData }
  },
  async loadCategoryData ({ dispatch, commit }, { query }) {
    const { items } = await ProductService.getProducts({ query })
    await dispatch('tax/calculateTaxes', { products: items }, { root: true })
    await dispatch('category-next/loadClerkCategories', { items }, { root: true })
    await dispatch('category-next/loadClerkMinMaxPrice', { query }, { root: true })
    return { items }
  }
}

export default actions
