import { SearchQuery } from 'storefront-query-builder'

export const createLoadUrlRewritesQuery = ({ requestPath }) => {
  let query = new SearchQuery();

  if (requestPath) {
    query = query.applyFilter({
      key: 'url_key',
      value: { [Array.isArray(requestPath) ? 'in' : 'match']: requestPath }
    })
  }

  return query
}
