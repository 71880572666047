import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const applyCurrencySign = (formattedPrice, { currencySign, priceFormat }) => {
  return priceFormat.replace('{sign}', currencySign).replace('{amount}', formattedPrice)
};

const getLocaleSeparators = (defaultLocale) => {
  return {
    decimal: (0.01).toLocaleString(defaultLocale).replace(/[0-9]/g, ''),
    group: (1000).toLocaleString(defaultLocale).replace(/[0-9]/g, '')
  }
};

const replaceSeparators = (formattedPrice, currencySeparators, separators) => {
  if (currencySeparators.decimal) formattedPrice = formattedPrice.replace(separators.decimal, currencySeparators.decimal);
  if (currencySeparators.group) formattedPrice = formattedPrice.replace(separators.group, currencySeparators.group);
  return formattedPrice;
};

/**
 * Converts number to price string
 * @param {Number} value
 * @param {String} storeView
 * @param {Boolean} removeTrailingZeroes
 */
export function price (value, storeView, removeTrailingZeroes) {
  if (isNaN(value)) {
    return value
  }
  const _storeView = storeView || currentStoreView();
  if (!_storeView.i18n) {
    return Number(value).toFixed(2)
  }

  const { defaultLocale, currencySign, currencyDecimal, currencyGroup, fractionDigits, priceFormat, trailingZeroesReplacement } = _storeView.i18n;

  const options = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits };

  let localePrice = Number(value).toLocaleString(defaultLocale, options);

  if (currencyDecimal !== '' || currencyGroup !== '') {
    localePrice = replaceSeparators(localePrice, { decimal: currencyDecimal, group: currencyGroup }, getLocaleSeparators(defaultLocale));
  }

  // If price is e.g. 123.00 (remainder is 0), then cut the zeroes and replace with string
  if (removeTrailingZeroes === true && trailingZeroesReplacement !== undefined && value % 1 === 0) {
    // Matches any amount of digits in a row with the end of line
    localePrice = localePrice.replace(/\d+$/, trailingZeroesReplacement)
  }

  const valueWithSign = applyCurrencySign(localePrice, { currencySign, priceFormat });

  return value >= 0 ? valueWithSign : '-' + valueWithSign;
}
