import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import { vatValidation } from 'config'
import { Module } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/core/i18n'

export const module: Module<RootState, any> = {
  namespaced: true,
  actions: {
    async check ({ dispatch }, { countryCode, vatNumber } = {}) {
      const URL = processLocalizedURLAddress(getApiEndpointUrl(vatValidation, 'check_endpoint')
        .replace('{{countryCode}}', countryCode)
        .replace('{{vatNumber}}', vatNumber))

      const res = await fetch(URL)
      const response = await res.json()

      if (response.code !== 200) {
        Logger.error('Problem with vat validation', 'vat-validation', response.result)()
        return Promise.reject(response.result)
      }

      if (response.result.is_valid) {
        dispatch('notification/spawnNotification', {
          type: 'success',
          message: response.result.request_message,
          action1: { label: i18n.t('OK') }
        }, { root: true })
      }

      Logger.info('Vat validation: ', 'vat-validation', response.result)();

      return Promise.resolve(response.result);
    }
  }
}
