import Task from '@vue-storefront/core/lib/sync/types/Task'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver'
import config from 'config'

const checkGiftCard = async (giftCardCode: string): Promise<Task> => {
  const url = processLocalizedURLAddress(getApiEndpointUrl(config.giftCard, 'check_endpoint').replace('{{giftCode}}', giftCardCode))

  return TaskQueue.execute({
    url,
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });
}

const activateGiftCard = async (giftCardCode: string): Promise<Task> => {
  const url = processLocalizedURLAddress(getApiEndpointUrl(config.giftCard, 'activate_endpoint').replace('{{giftCode}}', giftCardCode))

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });
}

const removeGiftCard = async (giftCardCode: string): Promise<Task> => {
  const url = processLocalizedURLAddress(getApiEndpointUrl(config.giftCard, 'remove_endpoint').replace('{{giftCode}}', giftCardCode))

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });
}

export const GiftCardService = {
  checkGiftCard,
  activateGiftCard,
  removeGiftCard
}
