const Home = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home.vue')
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue')
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue')
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const CmsPage = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/CmsPage.vue')
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
const Cart = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Cart.vue')
const OrderStatus = () => import(/* webpackChunkName: "vsf-msp-order-status" */ 'theme/pages/OrderStatus.vue')
const OrderCancelled = () => import(/* webpackChunkName: "vsf-order-cancelled" */ 'theme/pages/OrderCancelled.vue')
const Compare = () => import(/* webpackChunkName: "vsf-compare" */ 'theme/pages/Compare.vue')
const MyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount.vue')
const ResetPassword = () => import(/* webpackChunkName: "vsf-reset-password" */ 'theme/pages/ResetPassword.vue')
const AuthCallback = () => import(/* webpackChunkName: "vsf-social-login-pop-up" */ 'theme/pages/AuthCallback.vue')
const BlogCategory = () => import(/* webpackChunkName: "vsf-aheadworks-blog-category" */ 'src/themes/belomax/pages/BlogCategory.vue')
const BlogPost = () => import(/* webpackChunkName: "vsf-aheadworks-blog-post" */ 'src/themes/belomax/pages/BlogPost.vue')
const SubmitReturn = () => import(/* webpackChunkName: "vsf-submit-return" */ 'src/themes/belomax/pages/SubmitReturn.vue')
const SearchResults = () => import(/* webpackChunkName: "vsf-search" */ 'theme/pages/SearchResults.vue')
const Brands = () => import(/* webpackChunkName: "brands" */ 'theme/pages/Brands.vue')
const Maintenance = () => import(/* webpackChunkName: "maintenance" */ 'theme/pages/Maintenance.vue')

let routes = [
  { name: 'home', path: '/', component: Home },
  { name: 'checkout', path: '/checkout', component: Checkout, meta: { layout: 'minimal' } },
  { name: 'cart', path: '/checkout/cart', component: Cart, meta: { layout: 'minimal' } },
  { name: 'success', path: '/success', component: OrderStatus, meta: { layout: 'minimal' } },
  { name: 'cancel', path: '/cancel', component: OrderCancelled, meta: { title: 'Canceled order', layout: 'minimal' } },
  { name: 'my-account', path: '/my-account', component: MyAccount },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: MyAccount, props: { activeBlock: 'MyShippingDetails' } },
  { name: 'my-newsletter', path: '/my-account/newsletter', component: MyAccount, props: { activeBlock: 'MyNewsletter' } },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: { activeBlock: 'MyOrders' } },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' } },
  { name: 'my-recently-viewed', path: '/my-account/recently-viewed', component: MyAccount, props: { activeBlock: 'MyRecentlyViewed' } },
  { name: 'privacy-settings', path: '/my-account/privacy', component: MyAccount, props: { activeBlock: 'PrivacySettings' } },
  { name: 'compare', path: '/compare', component: Compare, props: { title: 'Compare Products' } },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'minimal' } },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: CmsPage },
  { name: 'create-password', path: '/create-password/token/:token/email/:email', component: ResetPassword },
  { name: 'social-log-in-pop-up', path: '/auth/callback/:provider', component: AuthCallback, meta: { layout: 'empty' } }, // Social Auth pop-up callback
  { name: 'my-social-profiles', path: '/my-account/social', component: MyAccount, props: { activeBlock: 'MySocialProfiles' } },
  { name: 'aheadworks-blog-category', path: '/blog/category/:slug', component: BlogCategory },
  { name: 'aheadworks-blog-post', path: '/blog/:slug', component: BlogPost },
  { name: 'aheadworks-blog', path: '/blog', component: BlogCategory },
  { name: 'brands', path: '/brands', component: Brands },
  { name: 'submit-return', path: '/submit-return', component: SubmitReturn, meta: { title: 'Submit Return', description: 'Submit Return redirect page' } },
  { name: 'mageworx_giftcards-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'search-results', path: '/search/:query', component: SearchResults },
  { name: 'maintenance', path: '/maintenance', component: Maintenance, meta: { layout: 'base' } },
  { name: 'page-not-found', path: '*', component: PageNotFound }
]

export default routes
