import { GetterTree } from 'vuex'
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState'
import RootState from '@vue-storefront/core/types/RootState'
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import { compareByLabel } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import { products } from 'config'
import trim from 'lodash-es/trim'
import toString from 'lodash-es/toString'
import forEach from 'lodash-es/forEach'
import isEmpty from 'lodash-es/isEmpty'
import last from 'lodash-es/last'
import { ExtendedState } from '.'

const getters: GetterTree<ExtendedState, RootState> = {
  getPaginationNumbersCount: state => (state as any).paginationNumbers,
  getAggregations: state => {
    const agg = []

    for (let i in state.aggregations) {
      const item = state.aggregations[i]

      if (item.buckets && item.buckets.length > 0) {
        item.buckets.forEach((bucket) => {
          agg[bucket.key] = bucket.doc_count
        })
      }
    }

    return agg
  },
  getAvailableFiltersFrom: (state, getters, rootState) => (aggregations) => {
    const filters = {}
    if (aggregations) { // populate filter aggregates
      // THIS IS CUSTOM FOR TKC
      const defaultFilters = [...products.defaultFilters]

      let cat = null

      if (!isEmpty(rootState.category.current)) {
        // For home page layered navigation
        cat = (rootState.category.current as any)
      } else if (!isEmpty(getters.getCurrentCategory)) {
        // For initial filter load inside CSR
        cat = getters.getCurrentCategory
      } else if (!isEmpty(last(getters.getCategories))) {
        // Works only if new category-next/loadCategory have been dispatched, and not loaded from cache by tag
        cat = last(getters.getCategories)
      }

      if (cat && cat.available_filter_by) {
        defaultFilters.push(...cat.available_filter_by)
      }

      // THIS IS CUSTOM FOR TKC

      for (let attrToFilter of defaultFilters) { // fill out the filter options
        let filterOptions: FilterVariant[] = []

        let uniqueFilterValues = new Set<string>()
        if (attrToFilter !== 'price') {
          if (aggregations['agg_terms_' + attrToFilter]) {
            let buckets = aggregations['agg_terms_' + attrToFilter].buckets
            if (aggregations['agg_terms_' + attrToFilter + '_options']) {
              buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
            }

            for (let option of buckets) {
              uniqueFilterValues.add(toString(option.key))
            }
          }

          uniqueFilterValues.forEach(key => {
            const label = optionLabel(rootState.attribute, { attributeKey: attrToFilter, optionId: key })
            if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
              filterOptions.push({
                id: key,
                label: label,
                type: attrToFilter
              })
            }
          });
          filters[attrToFilter] = filterOptions.sort(compareByLabel)
        } else { // special case is range filter for prices
          const storeView = rootState.storeView
          const currencySign = storeView.i18n.currencySign
          if (aggregations['agg_range_' + attrToFilter]) {
            let index = 0
            let count = aggregations['agg_range_' + attrToFilter].buckets.length
            for (let option of aggregations['agg_range_' + attrToFilter].buckets) {
              filterOptions.push({
                id: option.key,
                type: attrToFilter,
                from: option.from,
                to: option.to,
                label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : ''), // TODO: add better way for formatting, extract currency sign
                single: true
              })
              index++
            }
            filters[attrToFilter] = filterOptions
          }
        }
      }
      // Add sort to available filters
      let variants = []
      Object.keys(products.sortByAttributes).map(label => {
        variants.push({
          label: label,
          id: products.sortByAttributes[label],
          type: 'sort'
        })
      })
      filters['sort'] = variants
    }

    return filters
  },
  getSearchQueryCategories: state => (state as any).searchQueryCategories
}

export default getters
