import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { CompareModule } from '@vue-storefront/core/modules/compare'
import { WishlistModule } from '@vue-storefront/core/modules/wishlist'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsExtended } from './breadcrumbs-extended'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { SocialLogin } from './social-login'
import { InfoBanner } from './info-banner'
import { MultiSafePayPaymentModule } from './vsf-payment-multisafepay'
import { GoogleTagManagerModule } from './google-tag-manager';
import { PaymentBackendMethodsModule } from './payment-backend-methods'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { InitialResourcesModule } from '@vue-storefront/core/modules/initial-resources'
import { CategoryNextExtended } from './category-next-extended'
import { AheadworksBlog } from './aheadworks-blog'
import { VsfMappingFallback } from './vsf-mapping-fallback'
import { UrlRewrites } from './url-rewrites'
import { CartModuleExtended } from './cart-extended'

import { DeviceModule } from './device'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { VatValidation } from './vat-validation'
import { MageworxGiftcard } from './mageworx-giftcard'

import { AddressBook } from './address-book'
import { Brands } from './brands'
import { Clerk } from './clerk'
import { NewsletterModuleExtended } from './newsletter-extended'

import { PaymentPaypalModule } from './paypal'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(UrlRewrites)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(WishlistModule) // Trigger on wishlist icon click
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CategoryNextExtended)
  registerModule(CompareModule)
  registerModule(BreadcrumbsExtended)
  registerModule(GoogleTagManagerModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(InitialResourcesModule)
  registerModule(DeviceModule)
  registerModule(SocialLogin)
  registerModule(InfoBanner)
  registerModule(AheadworksBlog)
  registerModule(VsfMappingFallback)
  registerModule(MultiSafePayPaymentModule)
  registerModule(VatValidation)
  registerModule(MageworxGiftcard)
  registerModule(CartModuleExtended)
  registerModule(PaymentPaypalModule)
  registerModule(Brands)
  registerModule(Clerk)
  registerModule(NewsletterModuleExtended)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  AddressBook
]
