import RootState from '@vue-storefront/core/types/RootState'
import NewsletterModuleExtendedState from '../types/NewsletterModuleExtendedState'
import { Module } from 'vuex'
import actions from './actions'

export const module: Module<NewsletterModuleExtendedState, RootState> = {
  namespaced: true,
  state: {
    newsletterExtended: {}
  },
  actions
}
