import { StoreView } from '../types'
import { storeViews } from 'config'
import { getExtendedStoreviewConfig } from '.'

const getStoreViewByStoreCode = (storeCode: string): StoreView => {
  const { mapStoreUrlsFor = [] } = storeViews

  if (mapStoreUrlsFor.indexOf(storeCode) < 0) return null

  const storeView = getExtendedStoreviewConfig(storeViews[storeCode])

  return storeView
}

export default getStoreViewByStoreCode
