import { Module } from 'vuex'
import CategoryState from '@vue-storefront/core/modules/catalog-next/store/category/CategoryState'
import merge from 'lodash-es/merge'
import { categoryModule } from '@vue-storefront/core/modules/catalog-next/store/category'
import RootState from '@vue-storefront/core/types/RootState'
import actions from './actions'
import getters from './getters'

export type ExtendedState = CategoryState & {
  paginationNumbers: number,
  minPrice: number,
  maxPrice: number,
  searchQueryCategories: any[],
  aggregations: any
}

const categoryExtendedModule = merge(categoryModule, {
  state: {
    paginationNumbers: 3,
    minPrice: 0,
    maxPrice: 0,
    searchQueryCategories: [],
    aggregations: {}
  },
  mutations: {
    CATEGORY_SET_PAGINATION_NUMBERS (state, count: number = 2) {
      state.paginationNumbers = count
    },
    SET_MAX_PRICE (state, price) {
      state.maxPrice = price
    },
    SET_MIN_PRICE (state, price) {
      state.minPrice = price
    },
    CATEGORY_SET_SEARCH_QUERY_CATS (state, categories) {
      state.searchQueryCategories = categories
    },
    SET_AGGREGATIONS (state, aggregations) {
      state.aggregations = aggregations
    }
  },
  getters,
  actions
})

export const categoryNextExtended: Module<CategoryState, RootState> = categoryExtendedModule
