import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { VueRouter } from 'vue-router/types/router';

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>, router: VueRouter) {
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    const getProduct = (item) => {
      let product = {}

      const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes
      attributeMap.forEach(attribute => {
        const isObject = typeof attribute === 'object'
        let attributeField = isObject ? Object.keys(attribute)[0] : attribute
        let attributeName = isObject ? Object.values(attribute)[0] : attribute

        if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
          const value = item[attributeField] || product[attributeName]
          if (value) {
            product[attributeName] = value
          }
        }
      })

      const { category } = item
      if (category && category.length > 0) {
        product['item_category'] = category.slice(-1)[0].name
      }

      return product
    }

    const clearDataLayerItems = () => {
      (window as any)?.dataLayer.push({ items: null });
    }

    store.subscribe(({ type, payload }, state) => {
      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        clearDataLayerItems()
        GTM.trackEvent({
          event: 'add_to_cart',
          ecommerce: {
            currency: currencyCode,
            value: parseFloat(payload.product.price_incl_tax),
            items: [getProduct(payload.product)]
          }
        });
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        clearDataLayerItems()
        GTM.trackEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: currencyCode,
            value: parseFloat(payload.product.price_incl_tax),
            items: [getProduct(payload.product)]
          }
        });
      }

      // Measuring Views of Product Details
      if (type === 'recently-viewed/recently-viewed/ADD') {
        clearDataLayerItems()
        GTM.trackEvent({
          event: 'view_item',
          ecommerce: {
            currency: currencyCode,
            value: parseFloat(payload.product.price_incl_tax),
            items: [getProduct(payload.product)]
          }
        });
      }

      // Measuring Purchases
      if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {
        const orderId = payload.confirmation.backendOrderId
        const products = payload.order.products.map(product => getProduct(product))
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true, useCache: false }
        ).then(() => {
          clearDataLayerItems()

          const orderHistory = state.user.orders_history
          const order = state.user.orders_history ? orderHistory.items.find((order) => order['entity_id'].toString() === orderId) : null
          GTM.trackEvent({
            'event': 'purchase',
            'ecommerce': {
              'transaction_id': order ? order.increment_id : state.order.last_order_confirmation.confirmation.orderNumber,
              'affiliation': order ? order.store_name : state.order.last_order_confirmation.order.store_code,
              'value': order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_grand_total ? state.cart.platformTotals.base_grand_total : '',
              'currency': currencyCode,
              'tax': order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_tax_amount ? state.cart.platformTotals.base_tax_amount : '',
              'shipping': order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_shipping_amount ? state.cart.platformTotals.base_shipping_amount : '',
              'coupon': order ? order.coupon_code : '',
              'items': products
            }
          })
        })
      }

      // Adding a Product to a Wishlist
      if (type === 'wishlist/wishlist/ADD') {
        clearDataLayerItems()
        GTM.trackEvent({
          event: 'add_to_wishlist',
          ecommerce: {
            currency: currencyCode,
            value: parseFloat(payload.product.price_incl_tax),
            items: [getProduct(payload.product)]
          }
        });
      }

      // Adding a Product to a Compare
      if (type === 'compare/compare/ADD') {
        clearDataLayerItems()
        GTM.trackEvent({
          event: 'add_to_compare',
          ecommerce: {
            currency: currencyCode,
            value: parseFloat(payload.product.price_incl_tax),
            items: [getProduct(payload.product)]
          }
        });
      }
    })
  }
}
