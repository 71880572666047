import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { categoryNextExtended } from './store'
import { cartHooks } from '@vue-storefront/core/modules/cart/hooks'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const CategoryNextExtended: StorefrontModule = function ({ store }) {
  store.registerModule('category-next', categoryNextExtended)

  cartHooks.afterSync(() => {
    EventBus.$emit('cart-after-sync')
  })
}
