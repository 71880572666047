import { Logger } from '@vue-storefront/core/lib/logger';
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore';
import rootStore from '@vue-storefront/core/store';
import i18n from '@vue-storefront/i18n';
import toString from 'lodash-es/toString';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import Address from '../types/Address'
import { AddressBookState } from '../types/AddressBookState';

export const actions: ActionTree<AddressBookState, any> = {
  async addAddress ({ rootState }, [addressData, emitNotification = true]) {
    let currentUser = Object.assign({}, rootState.user.current)
    if (currentUser) {
      let customerId = currentUser.id
      let existAddress = currentUser.addresses.length ? currentUser.addresses : []

      for (let key in addressData) {
        if (!addressData.hasOwnProperty(key)) continue;

        if (existAddress.length > 0) {
          for (let i = 0; i < existAddress.length; i++) {
            if (addressData[key].default_shipping) {
              existAddress[i].default_shipping = false
            }
            if (addressData[key].default_billing) {
              existAddress[i].default_billing = false
            }
          }
        }

        existAddress.push({
          city: addressData[key].city,
          country_id: addressData[key].country,
          customer_id: customerId,
          firstname: addressData[key].firstName,
          lastname: addressData[key].lastName,
          postcode: addressData[key].postcode,
          region: {
            region: addressData[key].region
          },
          region_id: 0,
          street: [
            addressData[key].street,
            addressData[key].house
          ],
          telephone: addressData[key].phone,
          company: addressData[key].company,
          vat_id: addressData[key].vat_id,
          default_shipping: addressData[key].default_shipping,
          default_billing: addressData[key].default_billing
        })
      }

      let customerData = {
        customer: {
          id: customerId,
          email: currentUser.email,
          firstname: currentUser.firstname,
          lastname: currentUser.lastname,
          website_id: currentUser.website_id,
          store_id: currentUser.store_id,
          addresses: existAddress
        }
      }

      if (emitNotification) {
        Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Saving...'))
      }
      try {
        let url = rootStore.state.config.addressbook.create_endpoint.replace('{{customerId}}', customerId)
        if (rootStore.state.config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url)
        }

        await fetch(url, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(customerData)
        })
          .then(response => response.json())
          .then(data => {
            if (emitNotification) {
              Vue.prototype.$bus.$emit('notification-progress-stop')
            }
            if (data.code === 200) {
              rootStore.dispatch('user/me')
              if (emitNotification) {
                rootStore.dispatch('notification/spawnNotification', {
                  type: 'success',
                  message: i18n.t('Address has been saved successfully.'),
                  action1: { label: i18n.t('OK') }
                })
              }
            } else {
              Logger.error('Something went wrong. Try again in a few seconds.', 'address-book')()
            }
          })
      } catch (e) {
        if (emitNotification) {
          Vue.prototype.$bus.$emit('notification-progress-stop')
        }
        Logger.error('Something went wrong. Try again in a few seconds.', 'address-book')()
      }
    } else {
      Logger.error('You must have to login', 'address-book')()
    }
  },

  async updateAddress ({ rootState }, addressData) {
    if (addressData.currentAddressId) {
      let currentUser = Object.assign({}, rootState.user.current)
      let customerId = currentUser.id
      let existAddresses = currentUser.addresses.length ? currentUser.addresses : []

      let index
      for (let i = 0; i < existAddresses.length; i++) {
        if (addressData.newAddress.default_shipping) {
          existAddresses[i].default_shipping = false
        }
        if (addressData.newAddress.default_billing) {
          existAddresses[i].default_billing = false
        }
        if (toString(existAddresses[i].id) === toString(addressData.currentAddressId)) {
          index = i
        }
      }

      if (index >= 0) {
        existAddresses[index].firstname = addressData.newAddress.firstName
        existAddresses[index].lastname = addressData.newAddress.lastName
        existAddresses[index].street = [addressData.newAddress.street, addressData.newAddress.house]
        existAddresses[index].city = addressData.newAddress.city
        existAddresses[index].region = {
          region: addressData.newAddress.region ? addressData.newAddress.region : null
        }
        existAddresses[index].country_id = addressData.newAddress.country
        existAddresses[index].postcode = addressData.newAddress.postcode
        existAddresses[index].telephone = addressData.newAddress.phone ? addressData.newAddress.phone : ''
        existAddresses[index].default_shipping = addressData.newAddress.default_shipping
        existAddresses[index].default_billing = addressData.newAddress.default_billing

        if (!addressData.newAddress.company && !addressData.newAddress.vat_id) {
          delete existAddresses[index].vat_id
          delete existAddresses[index].company
        } else {
          existAddresses[index].company = addressData.newAddress.company
          existAddresses[index].vat_id = addressData.newAddress.vat_id
        }
      }

      let customerData = {
        customer: {
          id: customerId,
          email: currentUser.email,
          firstname: currentUser.firstname,
          lastname: currentUser.lastname,
          website_id: currentUser.website_id,
          store_id: currentUser.store_id,
          addresses: existAddresses
        }
      }

      Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Saving...'))
      try {
        let url = rootStore.state.config.addressbook.create_endpoint.replace('{{customerId}}', customerId)
        if (rootStore.state.config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url)
        }

        await fetch(url, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(customerData)
        })
          .then(response => response.json())
          .then(data => {
            Vue.prototype.$bus.$emit('notification-progress-stop')
            if (data.code === 200) {
              rootStore.dispatch('user/me')
              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: i18n.t('Address has been saved successfully.'),
                action1: { label: i18n.t('OK') }
              })
            } else {
              Logger.error('Something went wrong. Try again in a few seconds.', 'address-book')()
            }
          })
      } catch (e) {
        Vue.prototype.$bus.$emit('notification-progress-stop')
        Logger.error('Something went wrong. Try again in a few seconds.' + e, 'address-book')()
      }
    } else {
      Logger.error('Address Id missing', 'address-book')()
    }
  },

  async removeAddress ({ commit, rootState }, addressId) {
    if (addressId) {
      Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Removing...'))
      try {
        let url = rootStore.state.config.addressbook.remove_endpoint.replace('{{addressId}}', addressId)
        if (rootStore.state.config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url)
        }

        let addressData = {
          addressId: addressId
        }

        await fetch(url, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(addressData)
        })
          .then(response => response.json())
          .then(data => {
            Vue.prototype.$bus.$emit('notification-progress-stop')
            if (data.code === 200) {
              rootStore.dispatch('user/me')
              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: i18n.t('Address has been deleted successfully.'),
                action1: { label: i18n.t('OK') }
              })
            } else {
              Logger.error('Something went wrong. Try again in a few seconds.', 'address-book')()
            }
          })
      } catch (e) {
        Vue.prototype.$bus.$emit('notification-progress-stop')
        Logger.error('Something went wrong. Try again in a few seconds.' + e, 'address-book')()
      }
    } else {
      Logger.error('Address Id missing', 'address-book')()
    }
  }
}
