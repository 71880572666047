import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import config from 'config'
import i18n from '@vue-storefront/i18n'
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { Logger } from '@vue-storefront/core/lib/logger'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mutations } from './store/mutations'
import { getters } from './store/getters'
import { actions } from './store/actions'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

const PaymentStore = {
  namespaced: true,
  state: {
    payment_methods: [],
    issuers: [],
    issuer: null,
    paymentMethod: '',
    paymentStatusFetched: false
  },
  mutations,
  actions,
  getters
};

interface PayLoad {
  confirmation: Confirmation
}

interface Confirmation {
  magentoOrderId: string,
  orderNumber: string
}

export const MultiSafePayPaymentModule: StorefrontModule = ({ store, router }) => {
  store.registerModule('payment-service', PaymentStore);

  const onAfterPlaceOrder = async (payload: PayLoad) => {
    EventBus.$emit(
      'notification-progress-start',
      [i18n.t('Creating payment request'), '...'].join('')
    );

    const order_id = payload.confirmation.magentoOrderId;
    const payment_data = {
      order_id: order_id,
      payment_description: i18n.t('Order #') + ' ' + payload.confirmation.orderNumber,
      order_number: payload.confirmation.orderNumber
    };
    Logger.info('Payment data', 'Payment service', payment_data)();

    try {
      const createPaymentResponse = await store.dispatch(
        'payment-service/createPayment',
        payment_data
      );
      if (createPaymentResponse.code !== 200) {
        throw new Error(createPaymentResponse.result);
      }
      const order_comment_data = {
        order_id,
        order_comment: 'Payment is created for amount ' + createPaymentResponse.result.amount,
        status: 'pending_payment'
      };

      await store.dispatch('payment-service/postOrderComment', order_comment_data);

      await StorageManager.get('multisafepay').setItem('lastOrderConfirmation', store.state['order'].last_order_confirmation)
      await StorageManager.get('multisafepay').setItem('orderTotals', store.getters['cart/getTotals'])
      await StorageManager.get('multisafepay').setItem('checkoutFirstName', store.state['checkout'].personalDetails.firstName)

      window.location.href = createPaymentResponse.result.payment_gateway_url;
    } catch (err) {
      EventBus.$emit('notification-progress-stop');
      clearAllBodyScrollLocks()
      const errorData = {
        message: err.message,
        order_id: order_id
      };
      store.dispatch('payment-service/setError', errorData);
    }
  };

  let correctPaymentMethod = false;

  const placeOrder = function () {
    if (correctPaymentMethod) {
      EventBus.$emit('checkout-do-placeOrder', {});
    }
  };

  if (!isServer) {
    EventBus.$on('checkout-payment-method-changed', paymentMethodDetails => {
      EventBus.$off('order-after-placed', onAfterPlaceOrder);
      EventBus.$off('checkout-before-placeOrder', placeOrder);
      let mspMethods = config.orders.mspMethods
      if (
        mspMethods.indexOf(paymentMethodDetails) > -1
      ) {
        correctPaymentMethod = true;
        EventBus.$on('order-after-placed', onAfterPlaceOrder);
        EventBus.$on('checkout-before-placeOrder', placeOrder);
        Logger.info('checkout-before-placeOrder', 'Payment service')();
      } else {
        correctPaymentMethod = false;
      }
    });
  }
};
