import { module } from './store'
import { extendStore } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const STORAGE_KEY = 'cart'

export const CartModuleExtended: StorefrontModule = function () {
  StorageManager.init(STORAGE_KEY)

  extendStore(STORAGE_KEY, module)
}
